<template>
  <form action="javascript:;" id="filtro">
    <div class="field" id="switch"></div>
    <div class="field" id="periodi">
      <label for="periodi">Intervallo temporale</label>
      <select name="periodi" id="seleziona_periodi" @change="selezionePeriodo">
        <option v-for="(n, i) in 13" :value="i" :key="i">
          {{ lblSelPeriodo(i) }}
        </option>
      </select>
    </div>
    <div class="field" id="from">
      <label for="from">Da</label>
      <input type="date" name="from" v-model="from" />
    </div>
    <div class="field" id="to">
      <label for="to">A</label>
      <input type="date" name="to" v-model="to" />
    </div>
    <div class="field" id="dest">
      <label>Cliente</label>
      <select name="cli" ref="selectCli" @change="setCli" v-model="cliente">
        <option value="TUTTI">Tutti</option>
        <option v-for="c in clienti" :key="c.id" :value="c.codiceFiscale">
          {{ clienteToTitleCase(c) }}
        </option>
      </select>
    </div>
  </form>
</template>

<script>
import { parse, subMonths, startOfMonth, endOfMonth } from "date-fns";
import { format } from "date-fns-tz";
import it from "date-fns/locale/it";
import { toTitleCase, DateDefaults } from "mida4-web-app-utils";

const NAME = "FiltroSpSa";

export default {
  props: ["value", "hasFiltro", "clienti"],
  data() {
    return {
      NAME: NAME,
      filtro: this.value,
      cliente: "TUTTI"
    };
  },
  computed: {
    oggi() {
      return this.date2Str(new Date());
    },
    from: {
      get() {
        return this.date2Str(this.filtro.from);
      },
      set(val) {
        this.filtro.from = this.str2Date(val);
      },
    },
    to: {
      get() {
        return this.date2Str(this.filtro.to);
      },
      set(val) {
        this.filtro.to = this.str2Date(val);
      },
    },
    showRefreshFailed() {
      return this.aggiornabili && this.lastRefresh.ko.length > 0;
    },
  },
  methods: {
    reimpostaFiltro() {
      this.$emit("reimpostaFiltro");
    },
    getStart: (n) => startOfMonth(subMonths(new Date(), n)),
    getEnd: (n) =>
      n === 0 ? new Date() : endOfMonth(subMonths(new Date(), n)),
    selezionePeriodo(e) {
      const n = parseInt(e.target.value);
      if (n === 12) {
        document.querySelector("#from").style.display = "block";
        document.querySelector("#to").style.display = "block";
        this.filtro.from = this.getStart(0);
        this.filtro.to = new Date();
      } else {
        document.querySelector("#from").style.display = "none";
        document.querySelector("#to").style.display = "none";
        this.filtro.from = this.getStart(e.target.value);
        this.filtro.to = this.getEnd(e.target.value);
      }
    },
    lblSelPeriodo(p) {
      const n = parseInt(p);
      let ret = "Mese corrente";
      if (n > 0) {
        ret =
          n === 12
            ? "Selezione libera"
            : toTitleCase(
                format(this.getStart(p), DateDefaults.formats.MESE_ANNO, {
                  locale: it,
                })
              );
      }
      return ret;
    },
    clienteToTitleCase: (c) => toTitleCase(`${c.cognomeDenom} ${c.nome}`),
    date2Str: (d) => format(d, DateDefaults.formats.CLIENT),
    str2Date: (s) => parse(s, DateDefaults.formats.CLIENT, new Date()),
    setDest(dest) {
      this.filtro.dest = dest;
    },
    setCli(ev) {
      // Log.info('filtro documenti per cliente', ev.target.value)
      this.$emit("documentiPerCliente", ev.target.value);
    },
  },
};
</script>

<style src="@/styles/custom/components/documenti/_filtro.scss" lang="scss"></style>