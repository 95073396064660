<template>
  <LayoutSpSa>
    <section id="archivio-fatture">
      <button
        @click.prevent.stop="loadFatture"
        id="refresh-elenco-fatture"
        v-tooltip="'Aggiorna elenco'"
      >
        Aggiorna elenco
        <font-awesome-icon icon="redo" class="ml-3"></font-awesome-icon>
      </button>
      <FiltroSpSa
        ref="filtro"
        v-model="filtro"
        :clienti="clienti"
        @reimpostaFiltro="reimpostaFiltro"
        @documentiPerCliente="perCliente"
      />
      <FattureEmesseSpSa
        @scaricaFatture="scaricaFatture"
        :requestingDownload="requestingDownload"
        :showScaricaZip="!selectedCliente"
        :fatture="allFatture.filter(filtroApplicato)"
        :key="filtroB64()"
      />
    </section>
  </LayoutSpSa>
</template>

<script>
const NAME = "DocumentiSpSa";

import { startOfMonth, isBefore, isAfter, format } from "date-fns";
import { mapGetters, mapMutations, mapActions } from "vuex";

import { R, DateDefaults } from "mida4-web-app-utils";
import { downloadFile } from "mida4-web-app-utils/functions";
import {
  afterOrEq,
  beforeOrEq,
} from "mida4-fattura-rapida-spese-sanitarie/doc";

import LayoutSpSa from "@/components/_layout/LayoutSpSa";
import FiltroSpSa from "@/components/DocumentiSpSa/_common/FiltroSpSa";
import FattureEmesseSpSa from "@/components/DocumentiSpSa/FattureEmesseSpSa";

const FILTRO_BASE = {
  //dest: DestinazioneFattura.SDI,
  from: startOfMonth(new Date()),
  to: DateDefaults.OGGI,
  stato: "TUTTI",
};

export default {
  components: {
    LayoutSpSa,
    FiltroSpSa,
    FattureEmesseSpSa,
  },
  data() {
    return {
      NAME: NAME,
      filtro: FILTRO_BASE,
      selectedCliente: null,
      requestingDownload: false,
    };
  },
  computed: {
    ...mapGetters({
      activeUser: "user/getData",
      isUserLoggedIn: "auth/isUserLoggedIn",
      clienti: "clienti/getClienti",
      allFatture: "fatture/getFatture",
      qryAttiva: "fatture/getQryAttiva",
    }),
  },
  methods: {
    ...mapMutations({
      refreshQryAttiva: "fatture/setQryAttiva",
    }),
    ...mapActions({
      fetchClienti: "clienti/recuperaElenco",
      fetchFatture: "fatture/recuperaElenco",
    }),
    reimpostaFiltro() {
      this.filtro.stato = "TUTTI";
    },
    filtroApplicato(fatt) {
      // const filtroStato = R.isNil(this.filtro.stato) || this.filtro.stato === 'TUTTI' || fatt.statoInvio === this.filtro.stato
      const filtroDate =
        afterOrEq(fatt, this.filtro.from) && beforeOrEq(fatt, this.filtro.to);
      const filtroControparte =
        R.isNil(this.selectedCliente) ||
        fatt.cliente.codiceFiscale === this.selectedCliente;

      return filtroDate && filtroControparte;
    },
    async loadFatture() {
      this.toggleLoader(true);
      try {
        await this.fetchFatture();
        this.toggleLoader(false);
      } catch (e) {
        // this.$log.warn('Documenti - impossibile recuperare elenco fatture')
        this.toggleLoader(false);
        this.showError();
      }
    },
    // async aggiornaFattureSDI() {
    //   try {
    //     await this.$store.dispatch(`fatture/aggiornaInfoSDIAll`)
    //   }
    //   catch(e) {
    //     Log.warn('Documenti - impossibile aggiornare fatture SDI')
    //     this.showError()
    //   }
    // },
    async scaricaFatture() {
      this.toggleLoader(true);
      this.requestingDownload = true;
      try {
        const pIVA = this.activeUser.partitaIVA;
        const cognDen = this.activeUser.cognomeDenom;
        const fromStr = format(this.filtro.from, DateDefaults.formats.COMPAT);
        const toStr = format(this.filtro.to, DateDefaults.formats.COMPAT);
        const nomeFile = `${pIVA}-${cognDen}_fatture-emesse_${fromStr}-${toStr}.zip`;

        const res = await this.$api.zipArchivioFatture(this.filtro);

        if (res.status !== 200) {
          throw Error("Errore server");
        }
        downloadFile(nomeFile, res);
      } catch (e) {
        this.showError({
          title: "Scaricamento archivio fatture non riuscito",
          message: e.message,
        });
      }
      this.toggleLoader(false);
      this.requestingDownload = false;
    },
    perCliente(c) {
      this.selectedCliente = c.toUpperCase() === "TUTTI" ? null : c;
    },
    filtroB64() {
      return window.btoa(JSON.stringify(this.filtro) + this.selectedCliente);
    },
  },
  created() {
    this.filtro = {
      ...FILTRO_BASE,
      ...this.qryAttiva,
    };
  },
  async mounted() {
    const tasks = [];

    if (R.isEmpty(this.allFatture)) {
      tasks.push(this.fetchFatture());
    }
    if (R.isEmpty(this.clienti)) {
      tasks.push(this.fetchClienti());
    }

    if (!R.isEmpty(tasks) && this.isUserLoggedIn) {
      this.toggleLoader(true);
      try {
        await Promise.all(tasks);
        this.toggleLoader(false);
      } catch (e) {
        // this.$log.warn('Documenti - impossibile recuperare elenco fatture')
        this.toggleLoader(false);
        this.showError();
      }
    }
    // bus.$on('documentiPerCliente', this.perCliente)
  },
  // beforeDestroy(){
  //   bus.$off('documentiPerCliente', this.perCliente)
  // },
  watch: {
    filtro: {
      deep: true,
      async handler(newFiltro) {
        const needsRefresh =
          isBefore(newFiltro.from, this.qryAttiva.from) ||
          isAfter(newFiltro.to, this.qryAttiva.to);
        this.refreshQryAttiva({
          from: newFiltro.from,
          to: newFiltro.to,
        });
        if (needsRefresh) {
          await this.loadFatture();
        }
      },
    },
  },
  notifications: {
    showError: {
      title: "Errore di rete",
      message:
        "Non è stato possibile contattare il server. Controllare la connessione. Se l'errore persiste contattare l'assistenza.",
      type: "error",
    },
  },
};
</script>

<style src="@/styles/custom/views/_documenti.scss" lang="scss"></style>

